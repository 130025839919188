import { ContainerPaginaInicial, SubTitleLine } from "./style";
import primeiraImagem from "../../Assets/Fundos/PaginaInicial-01.png";
import fikSmartImagem from "../../Assets/LogoProdutos/FikSmart.svg";
import fikAutomationImagem from "../../Assets/LogoProdutos/FIKAUTOMATION.svg";
import fikAnalyticsImagem from "../../Assets/LogoProdutos/FIKANALYTCS.svg";
import fikOnImagem from "../../Assets/LogoProdutos/FIKON.svg";
import roboPaginaInicialImagem from "../../Assets/Robo/Robo.png";
import jobsLogo from "../../Assets/LogoProdutos/JOBS.svg";
import consultoriaLogo from "../../Assets/LogoProdutos/Consultoria.svg";
import adornoAzul from "../../Assets/Fundos/adornos-02.png";
import logoTora from "../../Assets/LogoClientes/Logos-01.png";
import logoABRH from "../../Assets/LogoClientes/Logos-02.png";
import logoVinci from "../../Assets/LogoClientes/Logos-03.png";
import logoCJF from "../../Assets/LogoClientes/Logos-04.png";
import logoKontabilita from "../../Assets/LogoClientes/Logos-05.png";
import logoGrupoZelo from "../../Assets/LogoClientes/Logos-06.png";
import logoGugs from "../../Assets/LogoClientes/Logos-07.png";
import logoClubeChalezinho from "../../Assets/LogoClientes/Logos-08.png";
import logoAiltoFerreiraFariaAdvAssociados from "../../Assets/LogoClientes/Logos-09.png";
import logoRobbyson from "../../Assets/LogoClientes/Logos-10.png";
import logoAeC from "../../Assets/LogoClientes/Logos-11.png";
import logoVerdemar from "../../Assets/LogoClientes/Logos-12.png";
import iconeArea01 from "../../Assets/IconesAreas/IconesAreas01.svg";
import iconeArea02 from "../../Assets/IconesAreas/IconesAreas02.svg";
import iconeArea03 from "../../Assets/IconesAreas/IconesAreas03.svg";
import iconeArea04 from "../../Assets/IconesAreas/IconesAreas04.svg";
import iconeArea05 from "../../Assets/IconesAreas/IconesAreas05.svg";
import iconeArea06 from "../../Assets/IconesAreas/IconesAreas06.svg";
import iconeArea07 from "../../Assets/IconesAreas/IconesAreas07.svg";
import { useContext, useEffect, useState } from "react";
import { ModalLeads } from "../../Components/ModalLeads";
import { BtnFaleComNossoTime } from "../../Components/BtnFaleCTime";
import FikContext from "../../Contexts/FikSiteContext";
import { BarraPrincipaisMercados } from '../../Components/BarraPrincipaisMercados';

const logos = [
    { src: logoTora, alt: "Logo da Tora" },
    { src: logoABRH, alt: "Logo da ABRH" },
    { src: logoVinci, alt: "Logo da Vinci" },
    { src: logoCJF, alt: "Logo do CJF" },
    { src: logoKontabilita, alt: "Logo da Kontabilita" },
    { src: logoGrupoZelo, alt: "Logo do Grupo Zelo" },
    { src: logoGugs, alt: "Logo do Gugs" },
    { src: logoClubeChalezinho, alt: "Logo do Clube Chalezinho" },
    { src: logoAiltoFerreiraFariaAdvAssociados, alt: "Logo do Ailto Ferreira Faria Advogados Associados" },
    { src: logoAeC, alt: "Logo da AeC" },
    { src: logoRobbyson, alt: "Logo do Robbyson" },
    { src: logoVerdemar, alt: "Logo do Verdemar" },
];

const iconesAreas = [
    { src: iconeArea01, alt: "Ícone de cabeça com engrenagem." },
    { src: iconeArea02, alt: "Ícone de ingrenagem com uma seta ao redor, demonstrando um sistema." },
    { src: iconeArea03, alt: "Ícone de uma calculadora" },
    { src: iconeArea04, alt: "Ícone de três caixas. Uma em cima da outra." },
    { src: iconeArea05, alt: "Ícone de planejamento" },
    { src: iconeArea06, alt: "Ícone de duas engrenagens." },
    { src: iconeArea07, alt: "Ícone de um martelo batendo na mesa. Representando o setor tributarios. " },
];

export const PaginaInicial = ({

}) => {
    const [visibleLogos, setVisibleLogos] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { visible, setVisible, handleClick } = useContext(FikContext);


    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => {
                const nextIndex = prevIndex + 3;
                return nextIndex < logos.length ? nextIndex : 0;
            });
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setVisibleLogos(logos.slice(currentIndex, currentIndex + 3));
    }, [currentIndex]);

    return (

        <ContainerPaginaInicial image={primeiraImagem}>
            <ModalLeads />
            <div className="parteUmPaginaInicial">
                <div className="containerPrimeiroParagrafo">
                    <div className="containerParagrafo">
                        <p className="descricao">
                            A TRANSFORMAÇÃO<br />
                            DIGITAL DA SUA<br />
                            EMPRESA COMEÇA AQUI!
                        </p>
                    </div>
                    <div>
                        <p className="descricao2">
                            Dispomos de várias soluções de software que podem ser implementadas em diferentes setores.
                        </p>
                    </div>

                    {/* <button className="falarComTime" onClick={handleClick}>FALE COM O NOSSO TIME</button> */}
                    <BtnFaleComNossoTime ariaLabel='Fale com nosso time pelo whatsapp.' onClick={handleClick} bgColo='var(--menu-blue-color)'>FALE COM O NOSSO TIME</BtnFaleComNossoTime>

                </div >
            </div>
            <div className="balaoDiv">
                <div className="conteudoBalaoEntreDivs">
                    <h3 className="tituloBalao">
                        <p>Acreditamos que é</p>
                        <p>na <strong>diferença</strong> que</p>
                        <p>a <strong>soma acontece.</strong></p>
                    </h3>
                    <ul className="ulBalao">
                        <li>
                            <p className="resultadosCardBalao">+14 mil</p>
                            <span className="tagTextoLiBalao">
                                Documentos/Contratos
                                <br />
                                digitais.
                            </span>
                        </li>
                        <li>
                            <p className="resultadosCardBalao">+18 mil</p>
                            <span className="tagTextoLiBalao">
                                Candidatos avaliados
                                <br />
                                na plataforma <span className="textoColoridoBalao">JOBS.</span>
                            </span>
                        </li>
                        <li>
                            <p className="resultadosCardBalao">+12 mil</p>
                            <span className="tagTextoLiBalao">
                                Horas treinadas
                                <br />
                                no portal <span className="textoColoridoBalao">LMS.</span>
                            </span>
                        </li>
                        <li>
                            <p className="resultadosCardBalao">+1 mil</p>
                            <span className="tagTextoLiBalao">
                                Processos automatizados  <br />
                                com <span className="textoColoridoBalao">RPA.</span>
                            </span>
                        </li>
                    </ul>

                </div>

            </div>
            <div className="testeClass">
                <div className="parteDoisPaginaInicial">
                    <div className="containerSegundoParagrafo">
                        <div className="containerCardAutomacaoRobotica">
                            <p className="descricaoSegundoTitulo">
                                A AUTOMAÇÃO<br />
                                É ROBÓTICA<br />
                                <span class="darkText">MAS COM<br /></span>
                                <span class="darkText">INTELIGÊNCIA<br /></span>
                                <span class="darkText">HUMANA!<br /></span>
                            </p>
                        </div>
                    </div >
                    <div className="containerVideo">
                        <video max-width='100vw' width="800" controls>
                            <source src="https://fikdigital.com.br/site/wp-content/uploads/2024/03/WhatsApp-Video-2024-03-22-at-14.01.49.mp4" type="video/mp4" />
                            Seu navegador não suporta o elemento de vídeo.
                        </video>
                    </div>
                </div >
                <div className="containerTerceiroParagrafo">
                    <div className="containerInformacoesTerceiroParagrafo">
                        <h3 className="somosTecnologiaTitle">SOMOS TECNOLOGIA!</h3>
                        <p>Com uma equipe diversificada de especialistas
                            apaixonados, a FIK é sinônimo de excelência
                            técnica e criatividade sem limites</p>
                        <p>Do desenvolvimento, consultoria até o Suporte,
                            nossa gama de serviços abrange uma visão
                            completa das necessidades digitais modernas</p>
                    </div>
                </div>
            </div>
            <div class="container" style={{ marginTop: '-200px', zIndex: 10 }}>
                <h3 class="nossasSolucoesTitle" id="nossas-solucoes">NOSSAS SOLUÇÕES</h3>
                <div class="line"></div>
            </div>
            <div className="containerUlSolucoes" >

                <ul className="containerProdutosFik">
                    <li>
                        <figure>
                            <img className="logoSVG" src={jobsLogo} alt="Logo do produto JOBS." />
                        </figure>
                        <p>Software de RH e DP para um
                            processo de Recrutamento,
                            Seleção e admissão ágil e digital.
                            Com nossa tecnologia, você e
                            seus candidatos podem realizar
                            todo o processo de forma 100%
                            remota, otimizando a triagem
                            com diversos recursos inovadores</p>

                        <div class="containerSaibaMaisLi">
                            <a aria-label="Te enviaremos para a pagina JOBS" href="/Jobs" class="ancoraSaberMais">Saber mais</a>
                            <div class="lineSaberMais"></div>
                        </div>
                    </li>
                    <li>
                        <figure>
                            <img className="logoSVG" src={fikAutomationImagem} alt="Logo do produto FIK AUTOMATION." />
                        </figure>
                        <p>Com RPA, processos que exigem
                            muita interação humana podem
                            ser automatizados. O FIK
                            Automation, atua como um
                            orquestrador inovador, que
                            gerencia os robôs e assegura
                            governança completa nas
                            operações de automação.</p>

                        <div class="containerSaibaMaisLi">
                            <a href="/fikAutomation" class="ancoraSaberMais">Saber mais</a>
                            <div class="lineSaberMais"></div>
                        </div>
                    </li>
                    <li>
                        <figure>
                            <img className="logoSVG" src={fikSmartImagem} alt="Logo do produto FIK SMART." />
                        </figure>
                        <p>Plataforma personalizável que
                            hospeda cursos e treinamentos,
                            proporcionando a melhor
                            experiência para seus usuários.</p>

                        <div class="containerSaibaMaisLi">
                            <a href="/FikSmart" class="ancoraSaberMais">Saber mais</a>
                            <div class="lineSaberMais"></div>
                        </div>
                    </li>
                    <li>
                        <figure>
                            <img className="logoSVG" src={consultoriaLogo} alt="Logo do produto consultoria." />
                        </figure>
                        <p>Nossa consultoria reestrutura os
                            processos do seu negócio para
                            trazer organização, governança,
                            agilidade e melhorar a
                            experiência do usuário. Utilizamos
                            tecnologia para transformar seu
                            negócio, otimizar processos,
                            desenvolver e gerir operações,
                            reduzindo custos operacionais.</p>

                        <div class="containerSaibaMaisLi">
                            <a aria-label="Te enviaremos para a pagina Consultoria" href="/consultoria" class="ancoraSaberMais">Saber mais</a>
                            <div class="lineSaberMais"></div>
                        </div>
                    </li>
                    <li>
                        <figure>
                            <img className="logoSVG" src={fikAnalyticsImagem} alt="Logo do produto FIK ANALYTICS." />
                        </figure>
                        <p>Software de RH e DP para um
                            processo de Recrutamento,
                            Seleção e admissão ágil e digital.
                            Com nossa tecnologia, você e
                            seus candidatos podem realizar
                            todo o processo de forma 100%
                            remota, otimizando a triagem
                            com diversos recursos inovadores</p>

                        <div class="containerSaibaMaisLi">
                            <a aria-label="Te enviaremos para a pagina FikAnalytics" href="/FikAnalytics" class="ancoraSaberMais">Saber mais</a>
                            <div class="lineSaberMais"></div>
                        </div>
                    </li>
                    <li>
                        <figure>
                            <img className="logoSVG" src={fikOnImagem} alt="Logo do produto FIK ON." />
                        </figure>
                        <p>Um Cofre de Senhas, a gestão de
                            acessos fica mais segura e
                            eficiente, permitindo que os
                            colaboradores se concentrem em
                            suas atividades principais sem se
                            preocupar com logins e senhas.</p>

                        <div class="containerSaibaMaisLi">
                            <a aria-label="Te enviaremos para a pagina FikOn" href="/FikOn" class="ancoraSaberMais">Saber mais</a>
                            <div class="lineSaberMais"></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="containerBPONuvem">
                <div className="containerBPO">
                    <div className="containerBPOInfos" id='BPO'>
                        <h3 className="BPOTechTitle">BPO<br />
                            TECH
                        </h3>
                        <p>
                            Temos diversas ferramentas e soluções para
                            BPO na área de tecnologia, a terceirização
                            dessas funções permite que as empresas se
                            concentrem em suas principais competências,
                            enquanto a nossa equipe de BPO Tech cuidam
                            das tarefas específicas
                        </p>
                        {/* <button onClick={() => setVisible(true)}>INFORME-SE</button> */}
                        <BtnFaleComNossoTime ariaLabel='Fale com nosso time pelo whatsapp.' onClick={() => setVisible(true)} bgColor='var(--primary-color)'>INFORME-SE</BtnFaleComNossoTime>

                    </div>

                    <figure>
                        <img className="roboPaginaInicialImagem" src={roboPaginaInicialImagem} alt="Logo do produto FIK SMART." />
                    </figure>
                </div>
            </div>


            <div className="containerListaEAdornoAzul">
                <div className="divAdornoAzul">
                    <img className="adornoAzul" alt="imagem para customização de interface do site. Meramente grafica." src={adornoAzul} />
                </div>
                <BarraPrincipaisMercados />
                <div className="containerCasesDeSucesso">
                    <div className="cardCasesDeSucesso">
                        <div className="containerParceriasQueTransformam">
                            <h3>PARCERIAS QUE TRANSFORMAM:</h3 >
                            <h4>CASES DE SUCESSO COM A FIK DIGITAL</h4>
                        </div>
                        <div className="containerImagensLogoClientes">
                            {visibleLogos.map((logo, index) => (
                                <img
                                    key={index}
                                    className="imagensLogoClientes"
                                    src={logo.src}
                                    alt={logo.alt}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </ContainerPaginaInicial >
    );
};
