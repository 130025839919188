import styled from "styled-components";
import MidBackground from '../../Assets/FikSmart/MidBackground.png';
import imagemAdorno from "../../Assets/Fundos/adornos-02.png";
import AdornoBranco from "../../Assets/Fundos/adorno-branco.png";

export const ContainerFikSmart = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  font-size: var(--fontSize);
margin-top: 125px;

  span {
    font-size: var(--fontSize);
  }

  .containerAplicacoes {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100px auto 150px;
    width: 100vw;
    position: relative;
    z-index: 1;
  }

  .conteudoAplicacoes {
    position: relative;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--primary-color);
    color: #FFF;
    box-sizing: border-box;
    padding: 0px 0px;
    max-width: 100vw;
  }

  .top {
    width: calc(100% + 8px);
    height: 150px;
    background-image: url(${imagemAdorno});
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleX(-1);
    transform: scaleY(-1);

    @media (max-width: 768px) {
        height: 100px;
    }
  }

  .foot {
    width: calc(100% + 8px);
    height: 150px;
    background-image: url(${imagemAdorno});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleX(-1);
    

    @media (max-width: 768px) {
        height: 100px;
    }
  }


  .containerOQEFikSmart {
    display: flex;
    align-items: center;
    justify-content: center;    
    width: 100vw;
    height: 900px;
    background-image: url(${MidBackground});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20vh;

    @media (max-width: 768px) {
      height: 700px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding-right: 7vw;
    }
  }

  .topFaixaBranca {
    width: calc(100% + 8px);
    height: 150px;
    background-image: url(${AdornoBranco});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(-1, -1);
    margin-top: 5vh;
    margin-bottom: -30vh;
    z-index: 2;

    @media (max-width: 768px) {
      margin-bottom: -25vh;
      height: 80px;
    }
  }

.footFaixaBranca {
    width: calc(100% + 8px);
    height: 150px;
    background-image: url(${AdornoBranco});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleX(-1);
    margin-top: -10vh;
    z-index: 2;

    @media (max-width: 768px) {
      margin-top: -5vh;
      height: 60px;
    }
  }

`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 0;
  left: 50vw;
  text-align: left; 
  margin-right: 10vw;

  @media (max-width: 768px) {
    left: auto;
    margin-right: 5vw;
    width: 90%;
  }
`;

export const LogoContainer = styled.div`
  margin-top: 5vh;
  margin-bottom: 2vh;

  @media (max-width: 768px) {
   margin-left: 5vh;
  }
`;

export const TextBoldContainer = styled.div`
  font-size: 1.5em;
  line-height: 1.2;
  font-weight: bold;
  color: #333;
  margin-top: 10vh;

  @media (max-width: 768px) {
    font-size: 1.2em;
    margin-top: 5vh;
    margin-left: 5vh;
  }
`;

export const TextContainer = styled.div`
  font-size: 1.5em;
  line-height: 1.2;
  color: #333;
  margin-top: 10vh;
  margin-right: 45vw;

  @media (max-width: 768px) {
    font-size: 1.1em;
    margin-top: 5vh;
    margin-left: 5vh;
    width: 90%;
  }
`;

export const LogoImage = styled.img`
  min-width: 200px;
  width: 23vw;
  height: auto;

  @media (max-width: 768px) {
    width: 70vw;
  }
`;

export const Button = styled.button`
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 2rem 4rem;
  font-size: 1.rem;
  font-weight: bold;
  margin-top: 10vh;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 1.5rem 3rem;
    margin-top: 5vh;
    margin-left: 5vh;
    max-width: 300px;
  }
`;

export const TopBackground = styled.img`
  width: 45%;
  height: auto;
  position: absolute;
  left: 5vw;
  margin-top: 5vw;
  z-index: -1;
  object-fit: cover;

  @media (max-width: 768px) {
    position: relative; 
    width: 70%; 
    margin-left: auto;
    margin-right: auto;
    margin-top: 7vh;
  }
`;

export const MidSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const WriteTextBoldContainer = styled.div`
  font-size: 4em;
  font-weight: bold;
  color: #ffffff;
  margin-left: 55vw;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 2.5em;
    margin-left: 10vw;
  }
`;

export const NonClickableGrayButton = styled.div`
  background-color: rgba(196, 208, 217, 0.6);
  padding: 20px; 
  margin-left: 55vw;
  border-radius: 50px;
  display: flex; 
  align-items: center; 
  justify-content: center; 
  text-align: center;
  min-width: 500px; 
  width: 600px; 
  height: auto; 
  min-height: 100px; 
  border: 5px solid var(--menu-blue-color);
  box-sizing: border-box;
  margin-top: 5vh;

  @media (max-width: 768px) {
    min-width: 350px; 
    width: 350px;
    padding: 10px;  
    margin-left: 10vw;
  }
`;

export const GrayButtomTextContainer = styled.div`
  font-size: 1.2em;
  line-height: 1.5;
  color: var(--menu-blue-color);
  text-align: center;

  b {
    font-weight: bold;
  }

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

export const SubTitle = styled.div`
  position: relative;
  font-size: 2em;
  color: var(--primary-color);
  font-weight: bold;
  margin-left: 10vw;
  margin-top: 5vh;
  margin-bottom: 3vh;

  @media (max-width: 768px) {
    font-size: 1.3em;
    margin-left: 5vw;
  }
`;

export const SubTitleLine = styled(SubTitle)`
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: calc(100% - 10vw);
    height: 5px;
    background-color: var(--primary-color);
    margin-left: 10px;
    left: calc(10vw + 750px);
  }
  
  @media (max-width: 768px) {
    &::after {
      width: calc(100% - 5vw);
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2vh;
  padding: 0 2vw;
  gap: 2vw;
`;

export const NonClickableButton = styled.div`
  background-color: var(--primary-color);
  color: white;
  padding: 30px 30px;
  margin: 20px;
  border-radius: 50px;
  text-align: center;
  min-width: 300px;
  max-width: 300px;
  min-height: 200px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 300px;
`;

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px; 

  img {
    width: 80px; 
    height: auto;
    max-height: 100px;
  }
`;

export const BlueButtomTextBoldContainer = styled.div` 
  font-size: 1em;
  font-weight: bold;
  color: #ffffff;
  text-align: center; 
  margin-top: 10px; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

export const BlueButtomTextContainer = styled.div`
  font-size: 1em;
  color: #ffffff;
  text-align: center; 
  margin-top: 10px; 
  padding: 0 10px; 

  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;

export const InvertedSubTitle = styled.div`
  position: relative;
  font-size: 2em;
  color: var(--primary-color);
  font-weight: bold;
  margin-right: 10vw;
  margin-top: 20vh;
  margin-bottom: 3vh;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 1.3em;
    margin-right: 5vw;
    margin-top: 5vh;
  }
`;

export const InvertedSubTitleLine = styled(InvertedSubTitle)`
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: calc(10vw + 750px);
    height: 5px;
    background-color: var(--primary-color);
    z-index: -1; 
  }

  @media (max-width: 768px) {
    &::before {
      right: calc(5vw + 400px);
    }
  }

  z-index: 1;
`;

export const NonClickableWriteButton = styled.div`
  background-color: #ffffff;
  color: white;
  padding: 30px 30px;
  margin: 20px;
  border-radius: 50px;
  text-align: center;
  min-width: 300px;
  max-width: 300px;
  min-height: 200px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 300px;
  border: 5px solid var(--primary-color);
`;

export const WriteButtomTextBoldContainer = styled.div`
  font-size: 1em;
  font-weight: bold;
  color: var(--primary-color);
  text-align: center; 
  margin-top: 10px; 
  
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

export const WriteButtomTextContainer = styled.div`
  font-size: 1em;
  color: var(--primary-color);
  text-align: center; 
  margin-top: 10px; 
  padding: 0 10px; 

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-bottom: 20vh;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vh;
  }
`;

export const BottomTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5vw;
  max-width: 40vw;
  z-index: 2;

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 90vw;
    text-align: center;
    margin-top: 2vh;
  }
`;

export const BottomTitle = styled.h1`
  font-size: 3em;
  color: #ffffff;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 2vh;

  @media (max-width: 768px) {
    font-size: 2em;
    margin-top: 5vh;
    margin-bottom: 1vh;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`

export const NonClickableLightBlueButton = styled.div`
  background-color: var(--secondary-color);
  color: white;
  padding: 30px;
  margin: 20px;
  border-radius: 50px;
  text-align: center;
  min-width: 350px;
  max-width: 350px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 300px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 55%;
    background-image: url(${(props) => props.imgSrc});
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: 2;
    pointer-events: none;
  }

  > * {
    z-index: 3;
  }
`;


export const LightBlueButtomTextBoldContainer = styled.div`
  font-size: 1em;
  font-weight: bold;
  color: #ffffff;
  text-align: center; 
  margin-top: 60%; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

export const LightBlueButtomTextContainer = styled.div`
  font-size: 1em;
  color: #ffffff;
  text-align: center; 
  margin-top: 10px; 
  padding: 0 10px; 

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

