import styled from "styled-components";
import fundoTelaPrincipalJobs from "../../Assets/JOBS/JobsInicial.png";
import fundoAzulAdornos from '../../Assets/JOBS/adorno.svg';
import imagemAdorno from '../../Assets/JOBS/adornoJOBS.svg';
import tracoPontiagudoImagem from '../../Assets/JOBS/Linha.svg';



export const JobsStyleRetorno = styled.main`
  width: 100%;
font-size: var(--fontSize);
.paragrafoInformacoesJobs{
font-weight: 400;
 @media (min-width: 1050px) {
         font-size: 1.2em;
    }
}
  .containerPrincipalJobs {
    padding: 0 3%;
    width: 100%;
    min-height: 100vh;
    background-image: url(${fundoTelaPrincipalJobs});
    background-size: auto auto;
  background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) {
      padding: 0 10%;
      justify-content: end;
    }
  }

  .containerPrincipalJobsInfos {
    display: flex;
    flex-direction: column;
    max-width: 80%;

    @media (min-width: 768px) {
      max-width: 30%;
    }
 @media (min-width: 1050px) {
      max-width: 30%;
    }
  }

  .imagemJobs {
    width: 100%;
  }

  .fraseSobreDescricaoJobsInicial {
    font-size: 1.3em;
    font-weight: 900;
 @media (min-width: 1050px) {
         font-size: 1.5em;
    }
  }

  .falarComTime {
    margin: 10px 0;
    width: 100%;
    color: white;
    background-color: var(--menu-blue-color);
    padding: 20px 15px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 20px;
    margin-top: 50px;
  }

  .containerEntreTextosCardUm {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }



.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center; 
    list-style: none; 
    padding: 0 10%; 
 @media (min-width: 1050px) {
    flex-direction: row;
  display: flex;
    align-items: stretch; 
justify-content: center;;

}


  
}


.cardContainer li {
    display: flex;
    flex-direction: column;
align-items: center;
    gap: 20px;
   flex: 1; 
    margin: 10px; 
    box-shadow: 
        inset 0 0 4px rgba(0, 0, 0, 0.4), 
        0 0 15px rgba(0, 0, 0, 0.6);     
    padding: 30px 25px; 
    background-color: white;
    border-radius: 22px; 
    text-align: center;
   justify-content: space-between;
  
 @media (min-width: 1050px) {
  
  max-width: 350px;
    min-width: 350px;
}
}

.containerLinha{
        display: flex;
       flex-direction: column;
        align-items: stretch;
        justify-content: center;
       gap: 40px;

@media (min-width: 600px) {
         background-image: url(${tracoPontiagudoImagem});
         flex-direction: row;
}

}


.imgModulos{
width: 200px;
height: 200px;
}
.tituloCardModulos{
font-size: 1.6em;
color: var(--primary-color);
font-weight: 800;
}

.containerSoftwareCompletoRH{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap:25px ;
min-height: 200px;
padding: 0 10%;

}
.tituloSoftwareCompletoParaRH{
font-size: 1.6em;
font-weight: 900;
color: var(--primary-color);
@media (min-width: 1050px) {
font-size: 2.3em;
font-weight: 900;
}
}

.paragrafoSoftwareCompleto{
font-size: 1.2em;
font-weight: 900;
 @media (min-width: 1050px) {
font-size: 1.4em;
font-weight: 900;
}

}

.tituloPrincipalWDocumentosPossiveisDeAssinar{
font-size: 1.3em;
font-weight: 900;
 @media (min-width: 1050px) {
font-size: 1.8em;
font-weight: 900;
}
}
.tituloDocumentosPossiveisDeAssinar{
color:var(--primary-color);
font-size: 1.5em;
font-weight: 800;

}
.paragrafoDocumentosPossiveisDeAssinar{
font-size: 1.1em;
font-weight: 600;
}
.posicaoItensDivs{
display:flex;
flex-direction: column;
gap:15px;
}
.containerGlobalDocumentoRH{
display:flex;
flex-direction: column;
gap: 40px;
}
.containerEnglobarDocumentosEImagem{
margin-top:40px;
display:flex;
flex-direction: column;
 align-items: center;
gap: 50px;
 padding: 50px 3%;
img{
width: 40%;
height: 50%;

}
 @media (min-width: 1050px) {
img{
width: 30%;
height: 40%;

}
padding:  60px  10% 120px 10%;
flex-direction: row;
    }
}
.containerAzul{
display:grid;
grid-template-columns: 1fr;
grid-template-rows: 200px auto 200px;
width: 100vw;
position: relative;
margin-top: -25px;

@media (max-height: 600px) {
    margin-top: -20px;
}

@media (min-width: 1050px) {
    margin-top: -80px;
}

@media (min-width: 850px) {
    margin-top: -80px;
}


 .top{
  width: calc(100% + 8px);
  height: 200px;
  background-image: url(${imagemAdorno});
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  margin-top: 4px;
  margin-left: -4px;
}

.conteudo{
  position: relative;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2B9BC6;
  color : #FFF;
 box-sizing: border-box;
 padding: 0px 20px;
  max-width: 100vw;

.text{
  width: 80%;
  max-width: calc(100dvw - 20%);
display: flex;
flex-direction: column;
gap:90px;
}
}

.foot{
width: calc(100% + 8px);
  height: 200px;
  background-image: url(${imagemAdorno});
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
margin-top: -4px;
margin-left: -4px;
}

}






.ulFuncoesJobsCard {
    display: flex; /* Usando flexbox para organizar os itens */
    flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha */
    list-style: none; /* Remove os marcadores padrão da lista */
    padding: 0; /* Remove o padding padrão da lista */
    margin: 0; /* Remove a margem padrão da lista */
}

.liFuncoesJobsCard {
    border-radius: 8px; /* Bordas arredondadas */
    margin: 15px; /* Espaçamento entre os cards */
    padding: 10px; /* Espaçamento interno do card */
    flex: 1 1 calc(25% - 30px); /* Largura flexível (4 cards por linha) */
    box-sizing: border-box; /* Inclui padding e margem no cálculo da largura */
    text-align: center; /* Centraliza o conteúdo */
display: flex;
flex-direction: column;
gap: 24px;
}

.figureFuncoesJobs {
margin: 0; /* Remove margens da figura */
background-color: white; /* Cor de fundo cinza */
height:180px; ;
display: flex;
align-items: center;
justify-content: center;
border-radius: 20px;
}

.imgFuncoesJobs {
    max-width: 100%; /* Faz a imagem ocupar 100% do card */
    height: 80px; /* Mantém a proporção da imagem */
    width: 80px;
}

.containerTextoEVideo{
display:flex;
flex-direction: column;
gap: 30px;
video{
    width: 90%;
}
 @media (min-width: 1050px) {
    flex-direction: row;
}
}
.containerFaleComNossoTimeVideo{
display:flex;
flex-direction: column;
justify-content: space-around;
width: 50%;
gap: 30px;

h3{
font-size: 1.7em;
font-weight: 600;
}
span{
font-size: 1.2em;
font-weight: 400;
}
}
.btnFaleComNossoTimeTransparente{
color:white;
border: 2px solid;
border-radius: 10px;
min-height: 45px;
width: 250px;
padding: 5px;
}
 .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

.adorno {
    padding: 5% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    p {
      font-size: 1.5em;
      line-height: 1em;
      color: white;
    }

    form {
     margin-top: 20px;
    width: 100%;

 @media (min-width: 1050px) {
        img{
        width: 40%;
        height: 40%;
        }
        padding: 100px 10%;
        flex-direction: row;
    }

    }

    form > .inputs {
      background-color: transparent;
      border: 1px solid white;
      border-radius: 0px;
      padding: 10px 15px;
      font-size: 1em;
      line-height: 1em;
    }

    form > .inputs::placeholder {
      color: white;
    }

    .envio {
      padding: 10px 15px;
      font-size: 1em;
      background-color: white;
      color: var(--primary-color);
      font-weight: bold;
      min-width: 130px;;
    }
  }
.paragrafoNaoPercaTempo{
font-weight: 800;
font-size: 1.2em;
}

`;
