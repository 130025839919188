import styled from "styled-components";
import FundoAzul from '../../Assets/FikOn/FundoAzul.png';
import imagemAdorno from "../../Assets/Fundos/adornos-02.png";

export const ContainerFikOn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden; 
  font-size: var(--fontSize);
margin-top: 120px;
@media (max-width: 768px) {
margin-top: 200px;
    }

  span {
    font-size: var(--fontSize);
  }
  
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 100px auto 150px;
    width: 100vw;
    position: relative;
    z-index: 1;
  }

  .conteudo {
    position: relative;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: #FFF;
    box-sizing: border-box;
    padding: 0px 0px;
    max-width: 100vw;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .top {
    width: calc(100% + 8px);
    height: 150px;
    background-image: url(${imagemAdorno});
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleX(-1);
    transform: scaleY(-1);

    @media (max-width: 768px) {
        height: 100px;
    }
  }

  .foot {
    width: calc(100% + 8px);
    height: 150px;
    background-image: url(${imagemAdorno});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scaleX(-1);
    

    @media (max-width: 768px) {
        height: 100px;
    }
  }
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const MidSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5vh;
  position: relative;
`;

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
  z-index: 0;
  margin-bottom: 2vh;
`;

export const LogoContainer = styled.div`
  margin-top: 10vh;
  margin-left: 10vw;
  margin-bottom: 2vh;
`;

export const TextBoldContainer = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-top: 10vh;
  margin-left: 10vw;

  @media (max-width: 768px) {
    font-size: 1.3em;
  }

`;

export const TextContainer = styled.div`
  font-size: 1.5em;
  color: #333;
  margin-left: 10vw;
  margin-top: 10vh;

  @media (max-width: 768px) {
    font-size: 1.3em;
    margin-left: 10vw;
    margin-top: 10vh;
  }
`;

export const WriteTextBoldContainer = styled.div`
  font-size: 1.3em;
  font-weight: bold;
  color: #ffffff;
  align-items: center;
`;

export const WriteTextContainer = styled.div`
  font-size: 1em;
  color: #ffffff;
  align-items: center;
  margin-top: 5vh;
`;

export const LogoImage = styled.img`
  min-width: 200px;
  width: 20vw;
  height: auto;
`;

export const Button = styled.button`
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 2rem 4rem;
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 10vw;
  margin-top: 10vh;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 1.25em;
    padding: 1.5rem 3rem;
    margin-left: 10vw;
    margin-top: 5vh;
    max-width: 300px;
  }
`;

export const SideImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: 7%;
  right: 0vw;
  z-index: -1;
  object-fit: contain;
  max-width: 50%;
  @media (max-width: 768px) {
    width: 60vw;
  }
`;

export const SubTitle = styled.div`
  position: relative;
  font-size: 2em;
  color: var(--primary-color);
  font-weight: bold;
  margin-left: 10vw;
  margin-top: 10vh;

  @media (max-width: 768px) {
    font-size: 1.3em;
    margin-left: 5vw;
    margin-top: 5vh;
  }
`;

export const SubTitleLine = styled(SubTitle)`
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: calc(100% - 10vw);
    height: 5px;
    background-color: var(--primary-color);
    margin-left: 10px;
  }
  
  @media (max-width: 768px) {
    &::after {
      width: calc(100% - 5vw);
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 50px;
  gap: 50px;
`;

export const NonClickableButton = styled.div`
  background-color: var(--primary-color);
  color: white;
  padding: 40px 50px;
  margin: 20px;
  border-radius: 50px;
  text-align: center;
  min-width: 300px; 
  max-width: 450px;
  flex: 1 1 45%; 

  @media (max-width: 768px) {
      min-width: 200px; 
      max-width: 350px;
    }
  
`;

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vw;  

  img {
    width: 150px;
    height: auto;

    @media (max-width: 768px) {
      width: 100px;
    }
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding-bottom: 20vh;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 1vh;
  }
`;

export const Funcionario = styled.img`
  width: 600px;
  height: auto;
  min-height: 500px;
  margin-top: 5vh;
  z-index: 1;
  object-fit: contain;
  max-width: 50%;

  @media (max-width: 768px) {
    width: 80vw;
    min-height: auto;
  }
`;

export const BottomTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5vw;
  max-width: 40vw;
  z-index: 2;

  @media (max-width: 768px) {
    margin-left: 0;
    max-width: 90vw;
    text-align: center;
    margin-top: 2vh;
  }
`;

export const BottomTitle = styled.h1`
  font-size: 4em;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 2vh;

  @media (max-width: 768px) {
    font-size: 3em;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }
`;

export const BottomText = styled.p`
  font-size: 1.2em;
  color: #ffffff;
  margin-right: 5vw;
  max-width: 30vw;
  word-break: break-word;

  @media (max-width: 768px) {
    font-size: 1.2em;
    margin-right: 0;
    max-width: 90vw;
    margin-top: 3vh;
  }
`;
