import { JobsStyleRetorno } from "./style";
import imagemLogoJobs from '../../Assets/JOBS/JOBSLogo.svg';
import iconeStart from '../../Assets/JOBS/IconeStart.png';
import iconeInsert from '../../Assets/JOBS/IconeInsert.png';
import iconeJourney from '../../Assets/JOBS/IconeJourney.png';
import imagemAssinatura from '../../Assets/JOBS/Assinatura.png';
import iconeJobs02 from '../../Assets/JOBS/IconesJobs02.svg';
import IconesJobss01 from '../../Assets/JOBS/IconesJobss01.svg';
import IconesJobss04 from '../../Assets/JOBS/IconesJobss04.svg';
import IconesJobss03 from '../../Assets/JOBS/IconesJobss03.svg';
import { useContext } from "react";
import FikContext from "../../Contexts/FikSiteContext";


export const PaginaJobs = () => {
    const { handleClick } = useContext(FikContext);

    return (
        <>
            <JobsStyleRetorno>
                <div className="containerPrincipalJobs">
                    <div className="containerPrincipalJobsInfos">
                        <img className="imagemJobs" src={imagemLogoJobs} alt="Imagem de log, escrito JOBS" />
                        <div className="containerEntreTextosCardUm">
                            <span className="fraseSobreDescricaoJobsInicial">TRANSFORMAMOS PROCESSOS
                                DE RH COM TECNOLOGIA!</span>

                            <p className="paragrafoInformacoesJobs">Portal JOBS permite uso interno e
                                externo para que seja feito todo o
                                acompanhamento do candidato
                                durante a sua jornada na empresa.</p>
                        </div>
                        <button aria-label="Fale com nosso time pelo whatsapp " onClick={handleClick} className="falarComTime">FALE COM O NOSSO TIME</button>
                    </div>
                </div>

                <div className="containerAzul">
                    <div className="top"> </div>
                    <div className="conteudo">
                        <div className="text">
                            <ul className="ulFuncoesJobsCard">
                                <li className="liFuncoesJobsCard">
                                    <figure className="figureFuncoesJobs">
                                        <img src={IconesJobss01} className="imgFuncoesJobs" alt="icone cadastramento de vagas" />
                                    </figure>
                                    <span>CADASTRAMENTO
                                        DE VAGAS</span>
                                </li>
                                <li className="liFuncoesJobsCard">
                                    <figure className="figureFuncoesJobs">
                                        <img src={iconeJobs02} className="imgFuncoesJobs" alt="icone acompanhamento de etapas" />
                                    </figure>
                                    <span>ACOMPANHAMENTO
                                        DE ETAPAS</span>
                                </li>
                                <li className="liFuncoesJobsCard">
                                    <figure className="figureFuncoesJobs">
                                        <img src={IconesJobss04} className="imgFuncoesJobs" alt="icone envio de documentos" />
                                    </figure>
                                    <span>ENVIO DE
                                        DOCUMENTOS</span>
                                </li>
                                <li className="liFuncoesJobsCard">
                                    <figure className="figureFuncoesJobs">
                                        <img src={IconesJobss03} className="imgFuncoesJobs" alt="icone assinaturas de contratos" />
                                    </figure>
                                    <span>ASSINATURA
                                        DE CONTRATO</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="foot"></div>
                </div>
                <div>
                    <div className="containerSoftwareCompletoRH">
                        <h2 className="tituloSoftwareCompletoParaRH">
                            SOFTWARE COMPLETO PARA RH
                        </h2>
                        <p className="paragrafoSoftwareCompleto">
                            CONHEÇA OS MÓDULOS DO PORTAL JOBS
                        </p>
                    </div>
                    <div className="cardContainer">
                        <ul className="containerLinha">
                            <li>
                                <img className="imgModulos" src={iconeStart} alt="IconeStart" />
                                <h3 className="tituloCardModulos">START</h3>
                                <p>
                                    Cadastre novas vagas e
                                    acompanhe toda a trilha de
                                    onboarding do seu candidato.
                                </p>

                            </li>
                            <li >
                                <img className="imgModulos" src={iconeInsert} alt="iconeInsert" />
                                <h3 className="tituloCardModulos">INSERT</h3>
                                <p>
                                    Acesse toda a documentação do
                                    candidato. Tudo de forma 100%
                                    online, inclusive a assinatura de
                                    contratos/carteiras de trabalho.
                                </p>
                            </li>
                            <li>
                                <img className="imgModulos" src={iconeJourney} alt="iconeJourney" />
                                <h3 className="tituloCardModulos">JOURNEY</h3>
                                <p>
                                    Controle o que cada
                                    colaborador terá de acesso
                                    dentro de cada etapa.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="containerEnglobarDocumentosEImagem">
                    <img src={imagemAssinatura} className="imgMao" alt="Mão de pessoa apontando para um dispositivo com tela." />
                    <div className="containerGlobalDocumentoRH">
                        <h2 className="tituloPrincipalWDocumentosPossiveisDeAssinar">QUAIS DOCUMENTOS DO RH SÃO
                            POSSÍVEIS ASSINAR ELETRONICAMENTE
                            NO PORTAL JOBS?</h2>
                        <div className="posicaoItensDivs">
                            <h3 className="tituloDocumentosPossiveisDeAssinar">DOCUMENTOS DE RH</h3>
                            <p className="paragrafoDocumentosPossiveisDeAssinar">Termos de compromisso e confidencial, politícas (de SI,
                                home office, etc), declarações (IR, dependentes etc)</p>
                        </div>

                        <div className="posicaoItensDivs" >
                            <h3 className="tituloDocumentosPossiveisDeAssinar">ONBOARDING</h3>
                            <p className="paragrafoDocumentosPossiveisDeAssinar">Kit admissional, ficha, contrato de trabalho, cadastro de
                                dependentes.</p>
                        </div>


                        <div className="posicaoItensDivs">
                            <h3 className="tituloDocumentosPossiveisDeAssinar">OFFBOARDING</h3>
                            <p className="paragrafoDocumentosPossiveisDeAssinar">TRCT (termo de rescisão de contrato de trabalho), termo
                                para seguro desemprego, declaração de aposentadoria.</p>
                        </div>


                        <div className="posicaoItensDivs">
                            <h3 className="tituloDocumentosPossiveisDeAssinar">BENEFÍCIOS</h3>
                            <p className="paragrafoDocumentosPossiveisDeAssinar">Formulários de adesão ou alteração (incluindo de
                                dependentes) para VT, plano de saúde, seguro de vida etc.</p>
                        </div>
                    </div>
                </div>
                <div className="containerAzul">
                    <div className="top"> </div>
                    <div className="conteudo">
                        <div className="text">
                            <div className="containerTextoEVideo">
                                <div className="containerFaleComNossoTimeVideo">
                                    <h3>TUDO 100% REMOTO</h3>
                                    <span>Liberte o seu time de RH dos <br />
                                        processos burocráticos.</span>
                                    <button aria-label="Fale com nosso time pelo whatsapp" onClick={handleClick} className="btnFaleComNossoTimeTransparente">FALE COM O NOSSO TIME</button>
                                </div>
                                <iframe style={{ aspectRatio: '16 / 9', minHeight: "300px" }} alt="Video apresentando a ferramenta JOBS." src="https://www.youtube.com/embed/TB1Tm_iYI4c" title="Conheça o JOBS Insert - Admissão Digital" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                            <div className="adorno">
                                <p className="paragrafoNaoPercaTempo">Não perca tempo.</p>

                                <p className="paragrafoNaoPercaTempo"> Vamos iniciar? </p>

                                <form>
                                    <label htmlFor="nome" className="sr-only">Nome</label>
                                    <input id="nome" className="inputs" placeholder="Nome" />

                                    <label htmlFor="cargo" className="sr-only">Cargo</label>
                                    <input id="cargo" className="inputs" placeholder="Cargo" />

                                    <label htmlFor="email" className="sr-only">E-mail</label>
                                    <input id="email" className="inputs" placeholder="E-mail" />

                                    <button aria-label="Enviar seus dados para nosso time entrar em contato." className="envio">ENVIAR</button>
                                </form>

                            </div>
                        </div>
                    </div>
                    <div className="foot"></div>
                </div>
            </JobsStyleRetorno>
        </>
    );
};
