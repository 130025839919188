import { Dialog } from 'primereact/dialog';
import { useContext, useState } from 'react';
import FikContext from '../../Contexts/FikSiteContext';
import './style.css';

export const ModalLeads = () => {
    const { visible, setVisible } = useContext(FikContext);

    return (
        <>
            <Dialog visible={visible} style={{ width: '80vw', zIndex: '8000' }} onHide={() => { if (!visible) return; setVisible(false); }}>
                <div className='containerModal'>
                    <div className='containerTituloEImagemBPO'>
                        <h3 className='tituloEsquerdo'>VAMOS JUNTOS <br />
                            <span>AUTOMATIZAR </span> OS <br />
                            RESULTADOS <br />
                            DA SUA EMPRESA!
                        </h3>
                    </div>
                    <div className='containerDireito'>
                        <div className='containerbtnFechar'>
                            <button className='btnFechar' onClick={() => setVisible(false)}>X</button>
                        </div>
                        <div>
                            <h3 className='tituloDireito'>PREENCHA OS <span className='estilizarPalavraDados'>DADOS</span> PARA MELHOR <br />
                                ENTENDIMENTO DO SEU NEGÓCIO.
                            </h3>

                            <form>
                                <div className='containerLabelItemSozinho'>
                                    <label>Nome</label>
                                    <input placeholder='Seu nome completo' />
                                </div>
                                <div className='containerDoisElementos'>
                                    <div className='containerLabel'>
                                        <label>Empresa</label>
                                        <input placeholder='Empresa' />
                                    </div>
                                    <div className='containerLabel'>
                                        <label></label>
                                        <select >
                                            <option>
                                                Segmento
                                            </option>
                                            <option>
                                                RH
                                            </option>
                                            <option>
                                                Suprimentos
                                            </option>
                                            <option>
                                                Financeiro
                                            </option>
                                            <option>
                                                Logística
                                            </option>
                                            <option>
                                                Planejamento
                                            </option>
                                            <option>
                                                Operacional
                                            </option>
                                            <option>
                                                Tributária
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div className='containerLabelCargo'>
                                    <label>Cargo</label>
                                    <select >
                                        <option>
                                            Dono/CEO/Presidente
                                        </option>
                                        <option>
                                            Vice-presidente
                                        </option>
                                        <option>
                                            Diretor/Gestor Executivo
                                        </option>
                                        <option>
                                            Gerente
                                        </option>
                                        <option>
                                            Coordenador/Supervisor
                                        </option>
                                        <option>
                                            Analista/Assistente
                                        </option>
                                        <option>
                                            Estagiário/Estudante
                                        </option>
                                        <option>
                                            Outros
                                        </option>
                                    </select>
                                </div>

                                <div className='containerDoisElementos'>
                                    <div className='containerLabel'>
                                        <label>E-mail corporativo</label>
                                        <input placeholder='exemplo@empresa.com.br' />
                                    </div>

                                    <div className='containerLabel'>
                                        <label>Telefone Corporativo</label>
                                        <input placeholder='(00) 00000-0000' />
                                    </div>
                                </div>

                                <div className='containerLabelItemSozinho'>
                                    <label>Mensagem</label>
                                    <textarea placeholder='Olá, boa tarde!' />
                                </div>
                                {/* <div> */}
                                <span className='containerCheckbox'>
                                    <input className='inputCheckbox' type='checkbox' />
                                    <label>Aceito a utilização dos dados cedidos nesse formulário para a realização de um contato comercial.</label>
                                </span>
                                {/* </div> */}
                                <button className='btnEnviar'>ENVIAR</button>
                            </form>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
};


