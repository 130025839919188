import styled from "styled-components";

export const FaixaStyle = styled.div`
  width: 95vw;
  height: auto;
  border: 2px solid var(--primary-color);
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: var(--fontSize);
  position: relative;
  /* outline: 1px red solid; */

  .faixa {
    position: relative;
    width: 100vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    background-color: white;
    border-radius: 15px;
  }

  .faixa > div {
    width: 100%;
    display: grid;
    grid-template-columns: 100px auto;
    position: relative;
    min-height: 67px;
    /* top:50%; */
  }

  .icon {
    position: relative;
    top: 50%;
    width: 30%;
  }

  .details {
    color: var(--primary-color);
    font-size: 1.1em;
    font-weight: 800;
    text-align: center;
    width: 80%;
    border-radius: 20px;
    margin-bottom: 10%;
  }

  .imgFaixa {
    display: none;
  }

  @media (min-width: 768px) {
    width: 100%;

    .faixa {
      display: flex;
      width: 100%;
      background-color: transparent;
      font-size: 1em;
      box-sizing: border-box;
      padding: 0 20px;
      margin: 0;
      gap: 0;
    }

    .faixa > div {
      width: 100%;
      display: grid;
      grid-template-columns: 80px auto;
      position: relative;
      min-height: 67px;
      z-index: 100;
    }

    .icon {
      width: 32px;
      height: auto;
      top: calc(50% - 22px);
      left: 9px;
      z-index: 200;
    }

    .imgFaixa {
      display: flex;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      left: 0;
    }

    .details {
      color: var(--primary-color);
      font-size: 0.98em;
      font-weight: bold;
      width: 99%;
      position: relative;
      top: calc(50% - 4px);
      trasnform: translateY(-50% );
      left: 0;
    }
  }
  @media (min-width: 1024px) {

    .faixa{
      font-size: 1.5em;
    }

    .icon {
      width: 2em;
      height: auto;
      left: 0;
      top: calc(50% - 1.375em);
      z-index: 200;
    }
    /* width: auto;

    .faixa {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      max-width: 76em;
      padding: 1em;
      background-color: transparent;
    }

    .icon {
      max-width: 5%;
      position: absolute;
      top: -103%;
      left: 13%;
      z-index: 200;
    }

    .imgFaixa {
      display: flex;
      width: 90%;
      max-width: 76em;
      position: relative;
    }*/

      .details {
      color: var(--primary-color);
      font-size: 0.9em;
      font-weight: bold;
      width: 98%;
      position: relative;
      top: calc(50% - 4px);
      trasnform: translateY(-50% );
      left: 0;
    }

    }
  @media (min-width: 1440px) {

    .faixa{
      font-size: 2em;
    }

    .icon {
      width: 2em;
      height: auto;
      left: 0;
      top: calc(50% - 1.375em);
      z-index: 200;
    }
     /* width: auto;

    .faixa {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      max-width: 76em;
      padding: 1em;
      background-color: transparent;
    }

    .icon {
      max-width: 5%;
      position: absolute;
      top: -108%;
      left: 13%;
      z-index: 200;
    }

    .imgFaixa {
      display: flex;
      width: 90%;
      max-width: 76em;
      position: relative;
    }

    .details {
      position: absolute;
      top: 50%;
      left: 25%;
      color: var(--primary-color);
      font-size: 1.4em;
      font-weight: bold;
      width: auto;
      z-index: 200;
    } */
  } 

  /* @media (min-width: 820px) {
    width: auto;
    position: relative;

    .icon {
      max-width: 4%;
      position: absolute;
      top: 24%;
      left: 8%;
    }

    .imgFaixa {
      display: flex;
      width: 90%;
      max-width: 80em;
    }

    .details {
      position: absolute;
      top: 50%;
      left: 20%;
      color: var(--primary-color);
      font-size: 1.2em;
      font-weight: bold;
      width: auto;
    }
  }

  @media (min-width: 1137px) {
    .details {
      position: absolute;
      top: 50%;
      left: 20%;
      color: var(--primary-color);
      font-size: 2em;
      font-weight: bold;
      width: auto;
    }
  }

  @media screen (min-width: 1476px){
    width: auto;
    position: relative;
    .icon {
      max-width: 4%;
      position: absolute;
      top: 10%;
      left: 8%;
    }
  } */
`;
