import React, { useContext } from "react";
import FikAnalyticsLogo from "../../Assets/FikAnalytics/FIK ANALYTCS.svg";
import imagemMaos from "../../Assets/FikAnalytics/Div_fik_analytics_-01.png";
import backgroundImage from "../../Assets/FikAnalytics/FundoFikAnalytcs-01.png";
import pessoas from "../../Assets/FikAnalytics/EmpresasFikAnalytics.png";
import { PaginaFikAnalytics, PaginaFikAnalytics2, PaginaFikAnalytics3, PaginaFikAnalytics4, PaginaFikAnalytics5 } from "./style";
import adorno from "../../Assets/FikAnalytics/adornos-01.png"
import adorno2 from "../../Assets/FikAnalytics/adornos-02.png"
import icon1 from "../../Assets/FikAnalytics/Icones Fik Analytcs-01.svg"
import icon2 from "../../Assets/FikAnalytics/Icones Fik Analytcs-02.svg"
import icon3 from "../../Assets/FikAnalytics/Icones Fik Analytcs-03.svg"
import icon4 from "../../Assets/FikAnalytics/Icones Fik Analytcs-04.svg"
import icon5 from "../../Assets/FikAnalytics/Icones Fik Analytcs-05.svg"
import icon6 from "../../Assets/FikAnalytics/Icones Fik Analytcs-06.svg"
import { Cards } from "../../Components/FikAnalytics/Cards/Cards";
import FikContext from "../../Contexts/FikSiteContext";

export const FikAnalytics = () => {
    const { handleClick } = useContext(FikContext);


    const fikAnalyticsIcons = [
        { id: 1, icon: icon1, description: "VISUALIZAÇÕES INTERATIVAS" },
        { id: 2, icon: icon2, description: "ANÁLISES AVANÇADAS" },
        { id: 3, icon: icon3, description: "INTEGRAÇÃO FÁCIL COM FONTES DE DADOS" },
        { id: 4, icon: icon4, description: "PLATAFORMA INTUITIVA E SEGURA" },
        { id: 5, icon: icon5, description: "DASHBOARDS PERSONALIZÁVEIS" },
        { id: 5, icon: icon6, description: "RELATÓRIOS DETALHADOS" }
    ]

    return (
        <>
            <PaginaFikAnalytics image={backgroundImage}>

                <div className="logoDescricao">

                    <div className="conteudo">

                        <div>
                            <img className="logo" src={FikAnalyticsLogo} alt="Logo da Fik Analytics" />
                        </div>

                        <p className="descricao">
                            TRANSFORME SEUS DADOS EM <br />
                            INSIGHTS PODEROSOS COM A  <br />
                            NOSSA PLATAFORMA FIK ANALYTICS
                        </p>

                        <p className="descricao2">
                            Descubra uma nova era de <br />
                            análise de dados com a FIK Analytics.
                        </p>

                        <button ariaLabel='Fale com nosso time pelo whatsapp.' onClick={handleClick} className="falarComTime">FALE COM O NOSSO TIME</button>
                    </div>

                </div >

            </PaginaFikAnalytics>

            <PaginaFikAnalytics2 image={imagemMaos}>

                <div className="descricao">
                    <div className="container">
                        <h2>
                            O QUE É O
                            <p>FIK ANALYTICS?</p>
                        </h2>
                        <div className="containerQuadro">
                            <div className="quadro">
                                <span className="informacao">
                                    Nossa solução de <strong>Business</strong> <br />
                                    <strong>Intelligence</strong> oferece visualizações  <br />
                                    interativas, análises avançadas e  <br />
                                    integração fácil com suas fontes de  <br />
                                    dados, tudo em uma plataforma  <br />
                                    intuitiva e segura
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

            </PaginaFikAnalytics2>

            <PaginaFikAnalytics3>
                <div className="beneficios">
                    <div className="esquerda">

                        <p>BENEFÍCIOS DO <br />
                            FIK ANALYTICS:</p>
                    </div>
                    <div className="direita">
                        <div>
                            <p>Explore tendências, identifique oportunidades de
                                crescimento e tome decisões estratégicas
                                informadas com nossos dashboards personalizáveis
                                e relatórios detalhados</p>
                        </div>
                    </div>
                </div>
                <div className="areaCard">
                    <ul>{
                        fikAnalyticsIcons.map(item => (
                            <Cards key={item.id} icon={item.icon} description={item.description} alt={item.description} />
                        ))
                    }

                    </ul>
                </div>

            </PaginaFikAnalytics3>

            <PaginaFikAnalytics4>
                <div className="esquerda">
                    <div className="esquerda-interno">

                        <img src={FikAnalyticsLogo} alt="Logo da Fik Analytics" />

                        <h3>Ideal para Empresas de Todos <br />
                            os Tamanhos e Setores!</h3>

                        <p>O FIK Analytics ajuda você a maximizar a <br />
                            eficiência operacional, melhorar a <br />
                            experiência do cliente e otimizar seus <br />
                            processos interno</p>
                    </div>

                </div>
                <div className="direita">
                    <img src={pessoas} alt="Descrição da imagem: A imagem mostra três situações de ambiente de trabalho. No lado esquerdo, um homem de óculos e camisa social está pensativo, segurando um tablet. No centro, há um grupo de quatro pessoas em uma mesa, discutindo algo com laptops abertos. Um homem de pé segura um bloco de notas enquanto fala. No canto inferior direito, uma mulher e um homem, ambos usando aventais, parecem estar trabalhando juntos, olhando para a tela de um laptop. A imagem é decorada com formas geométricas em tons de azul e turquesa." />
                </div>
            </PaginaFikAnalytics4>

            <PaginaFikAnalytics5>
                <div className="bordaSuperior">
                    <img src={adorno} alt="faixa superior da página" />
                </div>

                <div className="adorno">
                    <p>Não perca tempo.</p>

                    <p> Reduza custos e melhore a experiência do <br /> cliente, automatizando processos!</p>

                    <form className="containerForm">
                        <label htmlFor="nome" className="sr-only">Nome</label>
                        <input id="nome" className="inputs" placeholder="Nome" />

                        <label htmlFor="cargo" className="sr-only">Cargo</label>
                        <input id="cargo" className="inputs" placeholder="Cargo" />

                        <label htmlFor="email" className="sr-only">E-mail</label>
                        <input id="email" className="inputs" placeholder="E-mail" />

                        <button className="envio">ENVIAR</button>
                    </form>
                </div>

                <div className="bordaInferior">
                    <img src={adorno2} alt="faixa inferior da página" />
                </div>
            </PaginaFikAnalytics5>
        </ >
    );
};
