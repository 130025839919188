import styled from "styled-components";

export const PaginaFikAnalytics = styled.div`
  width: 100vw;
  background-image: none;
  background-color: white;
  font-size: var(--fontSize);
  display: flex;
  flex-direction: column;
  justify-content: center;
 margin-top: 18em;
 margin-bottom: 3em;
 max-height: none;

  .logoDescricao {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

    .conteudo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
  }

  .logo {
    max-width: 300px;
    width: 60vw;
    margin: 3rem auto;
  }

  .descricao {
    width: 90%;
    margin: 0 0 2rem 0;
    font-weight: 900;
    background-color: rgba(var(--menu-blue-color), 1);
    line-height: 1.2rem;
    font-size: 1rem;
    text-align: left;

    br {
      display: none;
    }
  }

  .descricao2 {
    width: 90%;
    background-color: rgba(var(--menu-blue-color), 1);
    font-size: 1rem;
    text-align: left;

    br {
      display: none;
    }
  }

  .falarComTime {
    max-width: 500px;
    width: 90%;
    margin: 2rem auto;
    color: white;
    background-color: var(--primary-color);
    padding: 15px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 14px;
  }

  @media (min-width: 768px) {
    background-color: white;
    font-size: var(--fontSize);
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3em;
    margin-bottom: 1em;

    
    .logoDescricao {
      background-image: url(${(props) => props.image});
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: row;
      gap: 5px;
      max-height: 61rem;
      align-self: center;
      width: 90vw;
      height: 80vh;
      align-items: center;
      justify-content: left;

      .conteudo {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: start;
      }
    }

    .logo {
      max-width: 15rem;
      margin: 1rem 3.5rem;
    }

    .descricao {
      width: 42%;
      height: auto;
      margin: 0.625rem 3.5rem;
      font-weight: 900;
      background-color: rgba(#102f51, 1);
      line-height: 1em;
      font-size: 1.5em;
    }

    .descricao2 {
      width: 41rem;
      margin: 0.625rem 3.5rem;
      background-color: rgba(#102f51, 1);
      font-size: 1.5em;
      line-height: 1em;

      br{
        display: block;
      }
    }

    .falarComTime {
      margin: 1.875rem 3.5rem;
      width: 33rem;
      color: white;
      background-color: var(--primary-color);
      padding: 20px;
      border-radius: 15px;
      font-weight: bold;
      font-size: 1.2em;
    }
  }
`;

export const PaginaFikAnalytics2 = styled.div`
  width: 100vw;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  font-size: var(--fontSize);
  display: flex;
  flex-direction: column;
  margin-top: -53px;


  .descricao {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .container {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
      gap: 50px;

      
      h2 {
        text-align: center;
        color: white;
        font-size: 5vh;
        font-weight: 700;
        width: 100%;

        p {
          font-size: 3vh;
        }
      }
    }
    .quadro {
      background-color: var(--secondary-color);
      border: 3px solid var(--menu-blue-color);
      border-radius: 15px;
      box-sizing: border-box;
      opacity: 70%;
      width: 80%;
      height: auto;
      margin: 0 auto;
      padding: 30px;

      .informacao {
        color: var(--menu-blue-color);
        font-size: 4vw;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 1px;
        width: 80%;
        strong {
          font-weight: bold;
        }
      }
    }
  }

  @media (min-width: 919px) {
    
    font-size: var(--fontSize);
    width: 100vw;
    min-height: 30vh;
    background-image: url(${(props) => props.image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 80%;
    font-family: Orbitron;
    font-size: var(--fontSize);
    position: relative;
    margin-top: -9%;
    padding: 5%;

    .descricao {
      display: flex;
      width: 50vw;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .container {
        display: flex;
        width: 50vw;
        gap: 30px;

        h2 {
          color: white;
          font-size: 4vh;
          font-weight: 700;
          width: 100%;
          text-align: center;
          margin-left: 0;

          p {
            font-size: 3vh;
          }
        }
      }

      .containerQuadro {
        display: flex;
        width: 50vw;
        justify-content: baseline;
      }

      .quadro {
        background-color: var(--secondary-color);
        border: 3px solid var(--menu-blue-color);
        box-sizing: border-box;
        opacity: 70%;
        border-radius: 25px;
        width: auto;
        height: auto;
        padding: 30px;
        box-sizing: border-box;

        .informacao {
          color: var(--menu-blue-color);
          line-height: 1em;
          font-size: 1.5em;
          font-weight: 400;
          width: 550px;
          strong {
            font-weight: bold;
          }
        }
      }
    }
  }
`;

export const PaginaFikAnalytics3 = styled.div`
  width: 100vw;
  height: auto;
  background-color: white;
  font-family: Orbitron;
  font-size: var(--fontSize);

  .beneficios {
    display: flex;
    flex-direction: column;
    width: 90vw;
    gap: 30px;
    margin-bottom: 20px;

    .esquerda {
      width: 100vw;
      height: auto;
      display: flex;
      margin: 20px auto;
      gap: 10px;

      span {
        display: none;
      }

      p {
        width: 100%;
        color: var(--primary-color);
        font-size: 2rem;
        letter-spacing: 0;
        margin-right: 0;
        font-weight: bolder;
        align-self: center;
        margin: 0 auto;
        text-align: center;

        br {
          display: none;
        }
      }
    }

    .direita {
      width: 100vw;
      height: auto;
      display: flex;
      margin-right: 0;
      gap: 10px;

      div {
        border: 1px solid var(--primary-color);
        border-radius: 15px;
        width: 90%;
        margin: 0 auto;

        p {
          color: var(--primary-color);
          padding: 20px;
          font-size: 1rem;
          line-height: 20px;
          align-self: center;
        }
      }
    }
  }

  .areaCard {
    width: 100vw;
    display: flex;
    justify-content: center;

    ul {
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
      gap: 10px;
      box-sizing: border-box;
      margin: 0 auto;
      padding: 0;
      justify-content: center;
    }
  }

  @media (min-width: 768px) {
    width: 100vw;
    background-color: white;
    font-family: Orbitron;
    font-size: var(--fontSize);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .beneficios {
      max-width: 80%;
      width: 100%;
      height: auto;
      margin: 10px auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .esquerda {
        &::before {
          content: "";
          display: block;
          width: 100%;
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translate(-100%, -7.5px);
          height: 5px;
          background-color: var(--primary-color);
        }

        position: relative;
        width: 50%;
        display: flex;


        p {
          color: var(--primary-color);
          font-size: 2em;
          letter-spacing: 0;
          font-weight: bolder;
          height: auto;
          align-self: center;
          text-align: justify;
          br {
            display: flex;
          }
        }
      }

      .direita {
        width: 50%;
        display: flex;

        div {
          border: 1px solid var(--primary-color);
          border-radius: 15px;
          width: 100%;
          align-self: flex-start;
          box-sizing: border-box;

          p {
            color: var(--primary-color);
            padding: auto;
            line-height: 1em;
            font-size: 1em;
            align-self: flex-start;
          }
        }
      }
    }

    .areaCard {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      /* margin-top: 6rem; */
      box-sizing: border-box;
      padding: 20px;

      ul {
        width: 100%;
        max-width: 80vw;
        height: auto;
        display: grid;
        grid-template-columns: auto auto;
        gap: 30px;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 0;
      }
    }
  
  }

  @media (min-width: 1024px) {
    .beneficios .esquerda p {
      font-size: 3em;
    }
     .beneficios .esquerda p {
        font-size: 44px;
    }
    
    .areaCard {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      /* margin-top: 6rem; */
      box-sizing: border-box;
      padding: 20px;

      ul {
        width: 100%;
        max-width: 80vw;
        height: auto;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 30px;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 0;      }
    }

  }

  @media (min-width: 1400px) {
    .beneficios .esquerda p {
      font-size: 52px;
    }

    .beneficios .direita div p {
      font-size: 1.4em;
    }
  }
  `;

export const PaginaFikAnalytics4 = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: white;
  font-family: Orbitron;
  font-size: var(--fontSize);
  margin: 0;
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;

  .esquerda {
    width: 100vw;
    min-heigth: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .esquerda-interno {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;
      padding: 5%;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 2em;
        line-height: 1em;
        color: var(--primary-color);
        font-weight: 600;
      }

      p {
        font-size: 1.2em;
        line-height: 1em;
        color: var(--menu-blue-color);
        font-weight: 500;
      }
    }

    img {
      width: 50%;
    }
  }

  .direita {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 100vw;
    min-height: 50vh;
    padding: 0;
    justify-content: flex-end;
    position: relative;
  }

  .direita img {
    position: absolute;
    /* left: 71px;  */
    margin: 0;
    padding: 0;
    width: 90%;
    height: auto;
  }

  @media (min-width: 768px) {
    font-size: var(--fontSize);
    width: 100vw;
    min-height: 100vh;
    background-color: white;
    font-family: Orbitron;
    font-size: var(--fontSize);
    margin: 0;
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    .esquerda {
      width: 40%;
      heigth: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .esquerda-interno {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 50px;
        width: 100%;
        padding: 15%;

        h3 {
          font-size: 2.1em;
          line-height: 1em;
          color: var(--primary-color);
          font-weight: 600;
        }

        p {
          font-size: 1.4em;
          line-height: 1em;
          color: var(--menu-blue-color);
          font-weight: 500;
        }
      }

      img {
        width: 17em;
      }
    }

    .direita {
      display: flex;
      justify-items: center;
      align-items: center;
      width: 60%;
      padding: 0;
      justify-content: flex-end;
      position: relative;
    }

    .direita img {
      position: absolute;
      /* left: 71px;  */
      margin: 0;
      padding: 0;
      width: 90%;
      height: auto;
    }
  }
`;

export const PaginaFikAnalytics5 = styled.div`
  width: 100vw;
  min-height: 80vh;
  background-color: white;
  font-family: Orbitron;
  font-size: var(--fontSize);
  display: flex;
  flex-direction: column;
  align-items: center;

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .bordaSuperior {
    display: flex;
    flex-direction: row-reverse;
  }

  img {
    transform: scaleX(-1);
  }

  .adorno {
    width: 100vw;
    min-height: 40vh;
    background-color: var(--primary-color);
    padding: 5% auto;
    margin: -3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    p {
      font-size: 1.5em;
      line-height: 1em;
      color: white;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;
      padding: 5%;
    }

    form > .inputs {
      background-color: var(--primary-color);
      border: 1px solid white;
      padding: 10px 15px;
      font-size: 1em;
      line-height: 1em;
    }

    form > .inputs::placeholder {
      color: white;
    }

    .envio {
      padding: 10px 15px;
      font-size: 1em;
      background-color: white;
      color: var(--primary-color);
      font-weight: bold;
    }
  }

  @media (min-width: 768px) {
    width: 100vw;
    background-color: white;
    font-family: Orbitron;
    font-size: var(--fontSize);
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 50px;

    .bordaSuperior {
      display: flex;
      flex-direction: row-reverse;
    }

    img {
      transform: scaleX(-1);
    }

    .adorno {
      width: 100vw;
      height: 40vh;
      background-color: var(--primary-color);
      padding: 0;
      margin: -3px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      p {
        font-size: 2em;
        color: white;
      }

      form {
        display: flex;
        height: auto;
        flex-direction: row;
        justify-content: center;
        gap: 15px;

      }

      form > .inputs {
        background-color: var(--primary-color);
        border: 1px solid white;
        padding: 10px 15px;
        height: 3rem;
        font-size: 1em;
        line-height: 1em;
        width: 180px;
        border-radius: 0;
      }

      form > .inputs::placeholder {
        color: white;
      }

      .envio {
        padding: 10px 15px;
        font-size: 1em;
        background-color: white;
        color: var(--primary-color);
        font-weight: bold;
        min-width: 170px;
      }
    }

    .bordaInferior {
      display: flex;
      flex-direction: row-reverse;
      margin-top: -41px;
    }

    img {
      transform: scaleX(-1);
    }
  }
`;
