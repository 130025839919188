import { Dialog } from 'primereact/dialog';
import { useContext } from 'react';
import FikContext from '../../Contexts/FikSiteContext';
import './style.css';
import { TiAttachment } from "react-icons/ti";

export const ModalTrabalheNaFik = () => {
    const { visibleModalTrabalheNaFik, setVisibleModalTrabalheNaFik } = useContext(FikContext);

    return (
        <Dialog
            visible={visibleModalTrabalheNaFik}
            style={{ width: '80vw', zIndex: '8000' }}
            onHide={() => { setVisibleModalTrabalheNaFik(false); }}
            role="dialog"
            aria-labelledby="dialog-title"
        >
            <div className='containerModal'>
                <div className='containerTituloEImagem'>
                    <h3 id="dialog-title" className='tituloEsquerdo'>FAÇA PARTE <br />
                        DO NOSSO <span>TIME </span>!
                    </h3>
                </div>
                <div className='containerDireito'>
                    <div className='containerbtnFechar'>
                        <button
                            className='btnFechar'
                            onClick={() => setVisibleModalTrabalheNaFik(false)}
                            aria-label="Fechar"
                        >
                            X
                        </button>
                    </div>
                    <div className='containerAltura'>
                        <h3 className='tituloDireito'>
                            SE VOCÊ SE IDENTIFICA COM <span className='estilizarPalavraDados'>A NOSSA CULTURA</span>, CADASTRE SEU CURRÍCULO E  <span className='estilizarPalavraDados'>VAMOS JUNTOS!</span>
                        </h3>

                        <form>
                            <div className='containerLabelItemSozinho'>
                                <label htmlFor="nome">Seu nome completo</label>
                                <input id="nome" placeholder='Seu nome completo' required />
                            </div>

                            <div className='containerLabelItemSozinho'>
                                <label htmlFor="email">E-mail</label>
                                <input id="email" placeholder='Seu melhor email' type="email" required />
                            </div>

                            <div className='containerDoisElementos'>
                                <div className='containerLabel'>
                                    <label htmlFor="celular">Celular com DDD</label>
                                    <input id="celular" placeholder='(00) 00000-0000' required />
                                </div>

                                <div className='containerLabel'>
                                    <label htmlFor="linkedin">Link para o seu linkedin</label>
                                    <input id="linkedin" placeholder='linkedin.com.br/in/seu-perfil' />
                                </div>
                            </div>

                            <div className='containerLabelCurriculo'>
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <TiAttachment /> ANEXAR CURRÍCULO
                                </label>
                                <input
                                    type="file"
                                    id="file-upload"
                                    accept=".pdf, .doc, .docx"
                                    style={{ display: "none" }}
                                    required
                                />
                            </div>

                            <button className='btnEnviar'>ENVIAR</button>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
