import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { type } from '@testing-library/user-event/dist/type';

export const FikContext = createContext();

export const FikContextProvider = ({ children }) => {

  const navigate = useNavigate()
  const [fontSize, setFontSize] = useState('16px');
  const [constraste, setContraste] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleModalTrabalheNaFik, setVisibleModalTrabalheNaFik] = useState(false);

  const alterarFonteSize = (val) => {
    console.log(fontSize);
    if (parseInt(fontSize) < 22 || parseInt(fontSize) > 16) {
      setFontSize((_act) => `${~~_act.toString().replace(/\D/gi, '') + val}px`);
    }
    if (parseInt(fontSize) < 16) {
      setFontSize('16');
      setFontSize((_act) => `${~~_act.toString().replace(/\D/gi, '') + val}px`);
    }
    if (parseInt(fontSize) > 22) {
      setFontSize('22');
      setFontSize((_act) => `${~~_act.toString().replace(/\D/gi, '') + val}px`);
    }
  }

  const alterarContraste = (val) => {
    setContraste(val);
  }

  const abrePagina = (page) => {
    navigate(page);
  }


  const phoneNumber = '55313656-3305'; // Substitua pelo seu número (formato: código do país + DDD + número)
  const message = 'Olá, gostaria de mais informações sobre '; // Mensagem que será enviada

  const handleClick = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <FikContext.Provider value={{ fontSize, setFontSize, constraste, setContraste, alterarFonteSize, alterarContraste, abrePagina, visible, setVisible, handleClick, visibleModalTrabalheNaFik, setVisibleModalTrabalheNaFik }}>
      {children}
    </FikContext.Provider>
  );
};

export default FikContext;
